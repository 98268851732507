var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingClient
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      !this.isLoadingClient
        ? _c(
            "v-tabs",
            {
              staticClass: "custom-tabs",
              attrs: {
                "active-class": "custom-active-tab",
                "slider-color": "transparent",
                "fixed-tabs": "",
              },
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "text-capitalize rounded-l black--text",
                  staticStyle: { "border-width": "2px 1px 2px 2px" },
                },
                [_vm._v(" Invoice ")]
              ),
              _c("v-tab", { staticClass: "text-capitalize black--text" }, [
                _vm._v(" HRDC "),
              ]),
              _c(
                "v-tab-item",
                [
                  _c("ComponentPrototypeCompanyClientInvoiceRecords", {
                    attrs: { companyId: this.companyId },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("ComponentPrototypeCompanyHRDC", {
                    attrs: { companyId: this.companyId },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("hr", { staticStyle: { border: "2px solid #F2F3F7" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }