var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingClient
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      !this.isLoadingClient
        ? _c(
            "v-tabs",
            {
              staticClass: "custom-tabs",
              attrs: {
                "active-class": "custom-active-tab",
                "slider-color": "transparent",
                "fixed-tabs": "",
              },
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "text-capitalize rounded-l black--text",
                  staticStyle: { "border-width": "2px 1px 2px 2px" },
                },
                [_vm._v(" client ")]
              ),
              _c(
                "v-tab",
                {
                  staticClass: "text-capitalize black--text rounded-r",
                  staticStyle: { "border-width": "2px 2px 2px 1px" },
                },
                [_vm._v(" Renewal PIC ")]
              ),
              _c(
                "v-tab-item",
                [
                  _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
                    _vm._v(" Client "),
                  ]),
                  _c(
                    "v-carousel",
                    {
                      attrs: {
                        height: "auto",
                        width: "auto",
                        continuous: false,
                        "hide-delimiters": "",
                      },
                    },
                    _vm._l(_vm.client, function (item, index) {
                      return _c(
                        "v-carousel-item",
                        { key: index },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "px-5 pt-5 mb-7 rounded-lg",
                              attrs: { flat: "", color: "blue-grey lighten-5" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Name : "),
                                  ]),
                                  _c("p", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.fname) +
                                        " " +
                                        _vm._s(item.lname) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Position : "),
                                  ]),
                                  _c("p", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.position ? item.position : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.checkIfCanAccessBonusOffer()
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", { staticClass: "text-no-wrap" }, [
                                        _vm._v(" Account Category : "),
                                      ]),
                                      _c("p", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.acc_category
                                                ? item.acc_category
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.checkIfCanAccessBonusOffer()
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", { staticClass: "text-no-wrap" }, [
                                        _vm._v(" Bonus Offer : "),
                                      ]),
                                      _c("p", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.bonus_offer
                                                ? item.bonus_offer
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Phone no : "),
                                  ]),
                                  _c("p", { staticClass: "text-right" }, [
                                    _vm._v(" " + _vm._s(item.mobile) + " "),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Email : "),
                                  ]),
                                  _c("p", { staticClass: "text-right" }, [
                                    _vm._v(" " + _vm._s(item.email) + " "),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Existing Client : "),
                                  ]),
                                  item.existing_client != null
                                    ? _c("p", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.existing_client) +
                                            " "
                                        ),
                                      ])
                                    : _c("p", [_vm._v(" - ")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Customer service : "),
                                  ]),
                                  item.cs_id != null
                                    ? _c("p", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.cs_name != null
                                                ? item.cs_name
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("p", { staticClass: "text-right" }, [
                                        _vm._v(" - "),
                                      ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Welcome Guide Send Date : "),
                                  ]),
                                  item.welcome_guide_sent != null
                                    ? _c("p", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.welcome_guide_sent != null
                                                ? _vm.convertDateZone(
                                                    item.welcome_guide_sent
                                                  )
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("p", { staticClass: "text-right" }, [
                                        _vm._v(" - "),
                                      ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Welcome WA Send Date "),
                                  ]),
                                  item.welcome_wa_sent != null
                                    ? _c("p", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.welcome_wa_sent != null
                                                ? _vm.convertDateZone(
                                                    item.welcome_wa_sent
                                                  )
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("p", { staticClass: "text-right" }),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Active : "),
                                  ]),
                                  item.active != null
                                    ? _c("p", { staticClass: "text-right" }, [
                                        _vm._v(" " + _vm._s(item.active) + " "),
                                      ])
                                    : _c("p", [_vm._v(" - ")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Gender : "),
                                  ]),
                                  _c("p", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.gender ? item.gender : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-no-wrap" }, [
                                    _vm._v(" Referral Code : "),
                                  ]),
                                  _c("p", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " " + _vm._s(item.referral_uuid) + " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("v-tab-item", [
                _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
                  _vm._v(" PIC "),
                ]),
                this.staff != null
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-carousel",
                          {
                            attrs: {
                              height: "auto",
                              width: "auto",
                              continuous: false,
                              "hide-delimiters": "",
                            },
                          },
                          _vm._l(_vm.staff, function (item, index) {
                            return _c(
                              "v-carousel-item",
                              { key: index },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "px-5 pt-5 mb-7 rounded-lg",
                                    attrs: {
                                      flat: "",
                                      color: "blue-grey lighten-5",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-no-wrap" },
                                          [_vm._v(" Name : ")]
                                        ),
                                        _c("p", { staticClass: "text-right" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.fname) +
                                              " " +
                                              _vm._s(item.lname) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-no-wrap" },
                                          [_vm._v(" Status : ")]
                                        ),
                                        item.deleted_at == null
                                          ? _c("p", [_vm._v(" Active ")])
                                          : _c("p", [_vm._v(" Inactive ")]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-no-wrap" },
                                          [_vm._v(" Phone no : ")]
                                        ),
                                        _c("p", { staticClass: "text-right" }, [
                                          _vm._v(
                                            " " + _vm._s(item.mobile) + " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-no-wrap" },
                                          [_vm._v(" Email : ")]
                                        ),
                                        _c("p", { staticClass: "text-right" }, [
                                          _vm._v(
                                            " " + _vm._s(item.email) + " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-no-wrap" },
                                          [_vm._v(" Role : ")]
                                        ),
                                        _c("p", { staticClass: "text-right" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.role_name.replaceAll(
                                                  "_",
                                                  " "
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-no-wrap" },
                                          [_vm._v(" Assigned Date : ")]
                                        ),
                                        item.pic_created_at != null
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.convertTimeZone(
                                                      item.pic_created_at
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("AWarning", {
                          attrs: { message: "No Person-in-charge assigned" },
                        }),
                      ],
                      1
                    ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("hr", { staticStyle: { border: "2px solid #F2F3F7" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }