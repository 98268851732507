<template>
    <v-container>
        <ANotAllowed :notAllowed="notAllowedAction" @close="closeNotAllowedAction" />
        <AError :api="this.api" />
        <div class="text-h5 font-weight-bold mt-n4 mb-4">
            Company Profile
        </div>
        <v-tabs class="custom-tabs" active-class="custom-active-tab" slider-color="transparent"
            background-color="transparent">
            <div class="d-flex justify-space-between" style="width: 100%;">
                <div class="d-flex ml-9" style="width: auto;">
                    <v-tab class="px-8">
                        <v-icon>
                            mdi-file-document-outline
                        </v-icon>
                        <div class="ml-2">
                            Details
                        </div>
                    </v-tab>
                    <v-tab class="px-10 ml-4"
                        v-if="checkIfAllowedExcel">
                        <v-icon>
                            mdi-file-excel-outline
                        </v-icon>
                        <div class="ml-2">
                            Excel
                        </div>
                    </v-tab>
                </div>
                <v-menu offset-y v-model="actionDrop">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="text-capitalize white--text pa-5" color="blue lighten-1" v-bind="attrs" v-on="on">
                            <div class="mr-2">
                                Actions
                            </div>
                            <v-icon v-if="actionDrop">
                                mdi-chevron-up
                            </v-icon>
                            <v-icon v-else>
                                mdi-chevron-down
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <div v-for="(item, index) in Actions" :key="index">
                            <v-list-item v-if="item.rolesAllowed.some(role => $store.getters.getRole.includes(role))"
                                @click="actions(item.action)">
                                <v-icon class="mx-2" size="20">
                                    {{ item.icon }}
                                </v-icon>
                                <div>
                                    {{ item.name }}
                                </div>
                            </v-list-item>
                            <v-list-item v-else @click="showNotAllowed">
                                <v-icon class="mx-2" size="20">
                                    {{ item.icon }}
                                </v-icon>
                                <div>
                                    {{ item.name }}
                                </div>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-menu>
            </div>
            <v-tab-item>
                <v-card class="pa-1 px-5 rounded-lg">
                <div class="text-h5 mt-8 font-weight-bold text-center">
                        Details
                    </div>
                    <v-row class="">
                        <v-col cols="6">
                            <ComponentPrototypeCompanyDetails />
                            <ComponentPrototypeCompanyClientTabs />
                            <ComponentStripeSubscription/>
                        </v-col>
                        <v-col cols="6">
                            <ComponentPrototypeCompanySpecialRemark  :companyId="this.$router.history.current.params.id"/>
                            <ComponentCompanyRenewal/>
                            <ComponentPrototypeCompanyPaymentStatusTabs/>
                            <ComponentReceipAndRefundTab/>
                            <!-- <ComponentPrototypeCompanyReceiptsAndInvoices /> -->
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            <v-tab-item
                v-if="checkIfAllowedExcel">
                <v-card class="pa-1 px-5 rounded-lg">
                    <div class="text-h5 mt-8 font-weight-bold text-center">
                        Excel
                    </div>
                    <v-row class="">
                        <v-col cols="6">
                            <ComponentPrototypeCompanySubscriptionHistoryTabs 
                                :companyId="this.$router.history.current.params.id"/>
                            <ComponentCompanyAAE
                                :companyId="this.$router.history.current.params.id"/>
                            <!-- <ComponentPrototypeCompanyClientInvoiceRecords /> -->
                            <ComponentInvoiceAndHRDCTab
                                :companyId="this.$router.history.current.params.id"/>
                        </v-col>
                        <v-col cols="6">
                            <ComponentPrototypeCompanySales 
                                :companyId="this.$router.history.current.params.id"/>
                            <ComponentPrototypeCompanyFunnel 
                                :companyId="this.$router.history.current.params.id"/>
                            <ComponentPrototypeCompanyRefund 
                                :companyId="this.$router.history.current.params.id"/>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import ANotAllowed from '../../components/common/ANotAllowed.vue';

import ComponentPrototypeCompanyDetails from '../../components/developer/prototype/ComponentPrototypeCompanyDetails.vue';
import ComponentPrototypeCompanyClientTabs from '../../components/developer/prototype/ComponentPrototypeCompanyClientTabs.vue';
import ComponentPrototypeCompanySpecialRemark from '../../components/developer/prototype/ComponentPrototypeCompanySpecialRemark.vue';
import ComponentPrototypeCompanyPaymentStatusTabs from '../../components/developer/prototype/ComponentPrototypeCompanyPaymentStatusTabs.vue';
// import ComponentPrototypeCompanyReceiptsAndInvoices from '../../components/developer/prototype/ComponentPrototypeCompanyReceiptsAndInvoices.vue';
import ComponentPrototypeCompanySubscriptionHistoryTabs from '../../components/developer/prototype/ComponentPrototypeCompanySubscriptionHistoryTabs.vue';
// import ComponentPrototypeCompanyClientInvoiceRecords from '../../components/developer/prototype/ComponentPrototypeCompanyClientInvoiceRecords.vue';
import ComponentPrototypeCompanySales from '../../components/developer/prototype/ComponentPrototypeCompanySales.vue';
import ComponentPrototypeCompanyFunnel from '../../components/developer/prototype/ComponentPrototypeCompanyFunnel.vue';
import ComponentPrototypeCompanyRefund from '../../components/developer/prototype/ComponentPrototypeCompanyRefund.vue';
import ComponentStripeSubscription from '../../components/company/ComponentStripeSubscription.vue';
import ComponentCompanyRenewal from '../../components/company/ComponentCompanyRenewal.vue';
import ComponentCompanyAAE from '../../components/company/ComponentCompanyAAE.vue';
import ComponentInvoiceAndHRDCTab from '../../components/company/tab/ComponentInvoiceAndHRDCTab.vue';
import ComponentReceipAndRefundTab from '../../components/company/tab/ComponentReceipAndRefundTab.vue';
// import mavonEditor from 'mavon-editor';
// import ComponentSubscriptionHistory from '../../components/company/ComponentSubscriptionHistory.vue';
// import ComponentCompanyRefund from '../../components/company/ComponentCompanyRefund.vue';
// import ComponentCompanySales from '../../components/company/ComponentCompanySales.vue';
// import ComponentCompanyHRDC from '../../components/company/ComponentCompanyHRDC.vue';
// import ComponentCompanyAAE from '../../components/company/ComponentCompanyAAE.vue';
// import ComponentCompanyFunnel from '../../components/company/ComponentCompanyFunnel.vue';
// import ANotAllowed from '../../components/common/ANotAllowed.vue';
// import AConfirmation from '../../components/common/AConfirmation.vue';
// import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
// import ComponentInvoiceCompany from '../../components/invoice/ComponentInvoiceCompany.vue';

export default {
    components: {
    ANotAllowed,
    ComponentPrototypeCompanyDetails,
    ComponentPrototypeCompanyClientTabs,
    ComponentPrototypeCompanySpecialRemark,
    ComponentPrototypeCompanyPaymentStatusTabs,
    // ComponentPrototypeCompanyReceiptsAndInvoices,
    ComponentPrototypeCompanySubscriptionHistoryTabs,
    ComponentPrototypeCompanySales,
    ComponentPrototypeCompanyFunnel,
    ComponentPrototypeCompanyRefund,
    ComponentStripeSubscription,
    ComponentCompanyRenewal,
    ComponentCompanyAAE,
    ComponentInvoiceAndHRDCTab,
    ComponentReceipAndRefundTab
},
    computed: mapState({
        checkIfAllowedExcel() {
            return this.excelRolesAllowed.some(role=>this.$store.getters.getRole.includes(role))
        }
    }),
    props: [
        //
    ],
    data: () => ({
        notAllowedAction: false,
        actionDrop: false,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        excelRolesAllowed:Array.from(["developer",'operation','customer_service','vision_admin','sales_team_lead','sales_manager']),
        Actions: [
            {
                name: 'Assign Task',
                icon: 'fa-edit',
                action: 'Followup',
                rolesAllowed: Array.from(["developer", "operation", 'salesperson', 'customer_service', 'vision_admin', 'sales_team_lead', 'sales_manager']),
            },
            {
                name: 'View company tasks',
                action: 'ViewTask',
                icon: 'bi-list-task',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin", "salesperson", "customer_service", "sales_team_lead", "sales_manager"]),
            },
            {
                name: 'Update renewal status',
                action: 'Renewal',
                icon: 'fa-clipboard',
                rolesAllowed: Array.from(["developer", 'customer_service', "salesperson", "vision_admin", "operation", "sales_team_lead", 'sales_manager'])
            },
            {
                name: 'Update payment status',
                action: 'Payment',
                icon: 'fa-clipboard',
                rolesAllowed: Array.from(["developer", "vision_admin", "operation"])
            },
            {
                name: 'Upload proof of payment',
                action: 'UploadReceipt',
                icon: 'fa-receipt',
                rolesAllowed: Array.from(["developer", 'customer_service', 'salesperson', 'operation', 'vision_admin', 'sales_team_lead', 'sales_manager'])
            },
            {
                name: 'Upload invoice',
                action: 'UploadInvoice',
                icon: 'fa-receipt',
                rolesAllowed: Array.from(["developer", 'operation', 'vision_admin']),
            },
            {
                name:'Upload refund attachment',
                action:'UploadRefund',
                icon:'fa-receipt',
                rolesAllowed: Array.from(["developer","operation","vision_admin"]),
            },
            {
                name: 'Add new user',
                action: 'AddNewUser',
                icon: 'fa-edit',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin"]),
            },
            {
                name: 'Update company detail',
                icon: 'fa-edit',
                action: "Edit",
                rolesAllowed: Array.from(["developer", "vision_admin", "operation"]),
            },
            {
                name: 'Update user detail',
                icon: 'fa-edit',
                action: 'EditUser',
                rolesAllowed: Array.from(["developer", 'vision_admin', 'operation']),
            },
            {
                name: 'Swap user Company',
                icon: 'fa-edit',
                action: 'SwapUserCompany',
                rolesAllowed: Array.from(["developer"]),
            },
            {
                name: 'Update AAE minutes',
                icon: 'fa-edit',
                action: "AddMinutes",
                rolesAllowed: Array.from(["developer", "vision_admin", "operation"]),
            },
            {
                name: 'Update renewal PIC',
                icon: 'fa-edit',
                action: 'PIC',
                rolesAllowed: Array.from(["developer", "operation"])
            },
            {
                name: 'Remove credit card',
                icon: 'fa-edit',
                action: 'RemoveCredit',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin"])
            },
            // {
            //     name: 'Send whatsapp',
            //     icon: 'bi-whatsapp',
            //     action: 'SendWhatssap',
            //     rolesAllowed: Array.from(["developer", "operation", "salesperson", "vision_admin", "customer_service"]),
            // },
            {
                name: 'Send email',
                icon: 'fa-envelope',
                action: 'SendEmail',
                rolesAllowed: Array.from(['developer', 'operation', 'vision_admin']),
            },

            {
                name: 'Activate subscription',
                action: 'ActivateSubscription',
                icon: 'bi-file-text-fill',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin"]),
            },
            {
                name: 'Cancel subscription',
                action: 'CancelSubscription',
                icon: 'bi-file-text-fill',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin"]),
            },

            {
                name: 'View receipts & invoices',
                icon: 'fa-book',
                action: 'ViewAttachment',
                rolesAllowed: Array.from(["developer", "vision_admin", "operation", "salesperson", "customer_service", "sales_team_lead", "sales_manager"]),
            },
            {
                name: 'View AAE minute record',
                icon: 'fa-book',
                action: 'AAEMinuteRecord',
                rolesAllowed: Array.from(["developer", "vision_admin", "operation", "sales_manager"]),
            },
            {
                name: 'View log',
                icon: 'fa-book',
                action: 'Log',
                rolesAllowed: Array.from(["developer", "vision_admin", "operation", "salesperson", "customer_service", "sales_team_lead", "sales_manager"]),
            },
        ],
    }),
    created() {
        //
    },
    mounted() {
        //
    },
    methods: {
        actions(actionName) {
            if (actionName == "Followup") {
                this.$router.push({ name: 'PagePICFollowupCreate', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "AAEMinuteRecord") {
                this.$router.push({ name: 'PageMinuteRecordList', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == 'AddNewUser') {
                this.$router.push({ name: 'PageUserCreate', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "ActivateSubscription") {
                this.$router.push({ name: 'PageActivateSubscription', params: { id: this.$router.history.current.params.id } });
                // this.$router.push({name:'PageManualDetail',params:{'id':"YesYouCanActivateSub"}});
                return 1;
            }
            if (actionName == 'SendEmail') {
                this.$router.push({ name: "PageSendEmail", params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "CancelSubscription") {
                this.$router.push({ name: 'PageCancelSubscription', params: { id: this.$router.history.current.params.id } });
                // this.$router.push({name:'PageManualDetail',params:{'id':"YesYouCanCancelSub"}});
                return 1;
            }
            if (actionName == "AddMinutes") {
                this.$router.push({
                    name: 'PageAAEMinutesAdd',
                    params: {
                        id: this.$router.history.current.params.id
                    },
                })
                return 1;
            }
            if (actionName == "RequestRefund") {
                this.$router.push({
                    name: 'PageRefundForm',
                    params: {
                        id: this.$router.history.current.params.id,
                    },
                })
                return 1;
            }
            if (actionName == "Log") {

                this.$router.push({
                    name: 'MockupCompanyLogs', params: { id: this.$router.history.current.params.id }
                });
                return 1;
            }
            if (actionName == "SendWhatssap") {
                this.$router.push({
                    name: 'PageWhatssapCreate',
                    params: {
                        'id': this.$router.history.current.params.id,
                    }
                })
                return 1;
            }
            if (actionName == "SwapUserCompany") {
                this.$router.push({ name: 'PageSwapUser', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "PIC") {
                this.$router.push({
                    name: 'PagePicUpdate'
                    , params: { id: this.$router.history.current.params.id }
                });
                return 1;
            }
            if (actionName == "Edit") {
                this.$router.push({ name: 'PageCompaniesUpdate', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName === "EditUser") {
                this.$router.push({ name: 'PageUserUpdate', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "RemoveCredit") {
                this.$router.push({ name: 'PageCreditCardDelete', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "UploadReceipt") {
                this.$router.push({ name: 'PageReceiptUpload', params: { 'attachment': "Receipt", id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "UploadInvoice") {
                // this.$router.push({ name: 'PageUploadXeroInvoice',query:{
                //     'company_id':this.$router.history.current.params.id,
                // }});
                this.$router.push({ name: 'PageReceiptUpload', params: { 'attachment': "Invoice", id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "UploadRefund") {
                this.$router.push({ name:'PageReceiptUpload', params: {'attachment': 'Refund', id: this.$router.history.current.params.id}});
                return 1;
            }
            if (actionName == "ViewTask") {
                this.$router.push({ name: 'PageTaskList', params: { 'id': this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "ViewAttachment") {
                this.$router.push({ name: 'PageAttachmentFormCompany', params: { 'id': this.$router.history.current.params.id } })
                return 1;
            }
            if (actionName == "Payment") {
                this.$store.commit('updateForm', this.latestPaymentForm);
            }
            this.$store.commit("updateAction", actionName);
            this.$router.push({ name: 'CompaniesForm', params: { id: this.$router.history.current.params.id } });
        },
        showNotAllowed() {
            this.notAllowedAction = true;
        },
        closeNotAllowed() {
            this.$router.go(-1);
        },
        closeNotAllowedAction() {
            this.notAllowedAction = false;
        },
    }
}
</script>

<style scoped>
.custom-tabs .v-tab {
    background-color: transparent;
    font-weight: bold;
    text-transform: capitalize;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.custom-active-tab {
    color: #00688F !important;
    background-color: white !important;
}
</style>