var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingAttachment
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      !this.isLoadingAttachment
        ? _c("div", [
            _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
              _vm._v(" Receipt & Invoices "),
            ]),
            Object.keys(_vm.attachment).length != 0
              ? _c(
                  "div",
                  [
                    _vm._l(this.attachment, function (item, index) {
                      return _c("div", { key: index }, [
                        index <= 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "pa-3 mb-2",
                                    attrs: {
                                      flat: "",
                                      color: "blue-grey lighten-5",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s("#" + (index + 1)) +
                                            " " +
                                            _vm._s(item.attachment_type) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "text-decoration-none",
                                              attrs: {
                                                href: item.attachment_url,
                                                target: "_blank",
                                                download: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.attachment_name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("v-col", [
                                          _c(
                                            "div",
                                            { staticClass: " text-right" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.convertTimeZone(
                                                      item.created_at
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    }),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end mt-3 mb-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "custom-button text-capitalize",
                            attrs: {
                              outlined: "",
                              to: {
                                name: "PageAttachmentFormCompany",
                                params: {
                                  id: this.$router.history.current.params.id,
                                },
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(" Show All "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
            Object.keys(_vm.attachment).length == 0 &&
            _vm.isLoadingAttachment == false
              ? _c("div", [
                  _c("span", { staticClass: "d-flex justify-start text-h7" }, [
                    _vm._v(" No Receipt or Invoices recorded "),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }