var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingClient
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      !this.isLoadingClient
        ? _c(
            "v-tabs",
            {
              staticClass: "custom-tabs",
              attrs: {
                "active-class": "custom-active-tab",
                "slider-color": "transparent",
                "fixed-tabs": "",
              },
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "text-capitalize rounded-l black--text",
                  staticStyle: { "border-width": "2px 2px 2px 2px" },
                },
                [_vm._v(" Receipt & Invoice ")]
              ),
              _c(
                "v-tab",
                {
                  staticClass: "text-capitalize rounded-r black--text",
                  staticStyle: { "border-width": "2px 2px 2px 2px" },
                },
                [_vm._v(" Refund ")]
              ),
              _c(
                "v-tab-item",
                [_c("ComponentPrototypeCompanyReceiptsAndInvoices")],
                1
              ),
              _c("v-tab-item", [_c("ComponentRefundAttachment")], 1),
            ],
            1
          )
        : _vm._e(),
      _c("hr", { staticStyle: { border: "2px solid #F2F3F7" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }