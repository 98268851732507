var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.subscription == null && this.isLoadingSubscription == false
        ? _c("AWarning", { attrs: { message: _vm.warningNoSubscription } })
        : _vm._e(),
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingSubscription
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
                  _vm._v(" Last Subscription From Stripe "),
                ]),
                this.subscription != null
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "px-5 pt-5 mb-7 rounded-lg",
                            attrs: { flat: "", color: "blue-grey lighten-5" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-no-wrap" }, [
                                  _vm._v(" Name : "),
                                ]),
                                _c("p", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.subscription.plan_name) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "pb-0" }, [
                                  _vm._v(" Status "),
                                ]),
                                _c("v-col", { staticClass: "text-right" }, [
                                  _c("p", { staticClass: "font-weight-bold" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.subscription.stripe_status) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-no-wrap" }, [
                                  _vm._v(" Price : "),
                                ]),
                                _vm.subscription.total_discount != null
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-decoration-line-through",
                                      },
                                      [
                                        _vm._v(
                                          " RM " +
                                            _vm._s(_vm.subscription.price) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c("p", [
                                      _vm._v(
                                        " RM " +
                                          _vm._s(_vm.subscription.price) +
                                          " "
                                      ),
                                    ]),
                              ]
                            ),
                            _vm.subscription.total_discount != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-no-wrap" }, [
                                      _vm._v(" Discount : "),
                                    ]),
                                    _c("p", { staticClass: "text-right" }, [
                                      _vm._v(
                                        " RM " +
                                          _vm._s(
                                            _vm.subscription.total_discount != 0
                                              ? _vm.subscription.total_discount.slice(
                                                  0,
                                                  -2
                                                )
                                              : 0
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.subscription.total_discount != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-no-wrap" }, [
                                      _vm._v(" Total price : "),
                                    ]),
                                    _vm.subscription.invoice_coupon != "null"
                                      ? _c("p", { staticClass: "text-right" })
                                      : _vm._e(),
                                    _vm.subscription.amount_paid != 0
                                      ? _c("p", { staticClass: "text-right" }, [
                                          _vm._v(
                                            ' "RM "' +
                                              _vm._s(
                                                _vm.subscription.amount_paid.slice(
                                                  0,
                                                  -2
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.subscription.amount_paid == 0
                                      ? _c("div", [
                                          _vm.subscription.invoice_coupon !=
                                          null
                                            ? _c("div", [
                                                _vm.subscription
                                                  .invoice_coupon == "Qn0HzJYH"
                                                  ? _c("p", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "Non-Stripe Payment"
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("p", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.subscription
                                                              .invoice_coupon
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ])
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-no-wrap" }, [
                                  _vm._v(" Referral code : "),
                                ]),
                                _c("p", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.subscription.coupon &&
                                          _vm.subscription.coupon !== "null"
                                          ? _vm.subscription.coupon
                                          : "Not applicable"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-no-wrap" }, [
                                  _vm._v(" Subscription date : "),
                                ]),
                                _c("p", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.convertTimeZone(
                                          _vm.subscription.period_start_date
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-no-wrap" }, [
                                  _vm._v(" Next renewal date : "),
                                ]),
                                _c("p", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.subscription.stripe_status.toLowerCase() !==
                                          "canceled" &&
                                          _vm.subscription.stripe_status.toLowerCase() !==
                                            "incomplete"
                                          ? _vm.convertTimeZone(
                                              _vm.subscription.period_end_date
                                            )
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end mt-n4 mb-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "custom-button text-capitalize",
                                attrs: { outlined: "" },
                                on: { click: _vm.readAllSubscription },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(" Show All ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", [_c("span", [_vm._v("No subscription found")])]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }