var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ANotAllowed", {
        attrs: { notAllowed: _vm.notAllowedAction },
        on: { close: _vm.closeNotAllowedAction },
      }),
      _c("AError", { attrs: { api: this.api } }),
      _c("div", { staticClass: "text-h5 font-weight-bold mt-n4 mb-4" }, [
        _vm._v(" Company Profile "),
      ]),
      _c(
        "v-tabs",
        {
          staticClass: "custom-tabs",
          attrs: {
            "active-class": "custom-active-tab",
            "slider-color": "transparent",
            "background-color": "transparent",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-space-between",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex ml-9", staticStyle: { width: "auto" } },
                [
                  _c(
                    "v-tab",
                    { staticClass: "px-8" },
                    [
                      _c("v-icon", [_vm._v(" mdi-file-document-outline ")]),
                      _c("div", { staticClass: "ml-2" }, [_vm._v(" Details ")]),
                    ],
                    1
                  ),
                  _vm.checkIfAllowedExcel
                    ? _c(
                        "v-tab",
                        { staticClass: "px-10 ml-4" },
                        [
                          _c("v-icon", [_vm._v(" mdi-file-excel-outline ")]),
                          _c("div", { staticClass: "ml-2" }, [
                            _vm._v(" Excel "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass:
                                    "text-capitalize white--text pa-5",
                                  attrs: { color: "blue lighten-1" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("div", { staticClass: "mr-2" }, [
                                _vm._v(" Actions "),
                              ]),
                              _vm.actionDrop
                                ? _c("v-icon", [_vm._v(" mdi-chevron-up ")])
                                : _c("v-icon", [_vm._v(" mdi-chevron-down ")]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.actionDrop,
                    callback: function ($$v) {
                      _vm.actionDrop = $$v
                    },
                    expression: "actionDrop",
                  },
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.Actions, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          item.rolesAllowed.some(function (role) {
                            return _vm.$store.getters.getRole.includes(role)
                          })
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.actions(item.action)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-2",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                                  ),
                                  _c("div", [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                  ]),
                                ],
                                1
                              )
                            : _c(
                                "v-list-item",
                                { on: { click: _vm.showNotAllowed } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-2",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                                  ),
                                  _c("div", [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                  ]),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { staticClass: "pa-1 px-5 rounded-lg" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-h5 mt-8 font-weight-bold text-center",
                    },
                    [_vm._v(" Details ")]
                  ),
                  _c(
                    "v-row",
                    {},
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("ComponentPrototypeCompanyDetails"),
                          _c("ComponentPrototypeCompanyClientTabs"),
                          _c("ComponentStripeSubscription"),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("ComponentPrototypeCompanySpecialRemark", {
                            attrs: {
                              companyId: this.$router.history.current.params.id,
                            },
                          }),
                          _c("ComponentCompanyRenewal"),
                          _c("ComponentPrototypeCompanyPaymentStatusTabs"),
                          _c("ComponentReceipAndRefundTab"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.checkIfAllowedExcel
            ? _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-1 px-5 rounded-lg" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-h5 mt-8 font-weight-bold text-center",
                        },
                        [_vm._v(" Excel ")]
                      ),
                      _c(
                        "v-row",
                        {},
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "ComponentPrototypeCompanySubscriptionHistoryTabs",
                                {
                                  attrs: {
                                    companyId:
                                      this.$router.history.current.params.id,
                                  },
                                }
                              ),
                              _c("ComponentCompanyAAE", {
                                attrs: {
                                  companyId:
                                    this.$router.history.current.params.id,
                                },
                              }),
                              _c("ComponentInvoiceAndHRDCTab", {
                                attrs: {
                                  companyId:
                                    this.$router.history.current.params.id,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("ComponentPrototypeCompanySales", {
                                attrs: {
                                  companyId:
                                    this.$router.history.current.params.id,
                                },
                              }),
                              _c("ComponentPrototypeCompanyFunnel", {
                                attrs: {
                                  companyId:
                                    this.$router.history.current.params.id,
                                },
                              }),
                              _c("ComponentPrototypeCompanyRefund", {
                                attrs: {
                                  companyId:
                                    this.$router.history.current.params.id,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }