var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingRenewal
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
                  _vm._v(" Renewal Update Status "),
                ]),
                _vm.renewalStatus != null
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "d-flex justify-start" }, [
                          _c("p", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(this.totalRenewalStatus) +
                                " renewal update 2023 "
                            ),
                          ]),
                        ]),
                        _c(
                          "v-carousel",
                          {
                            attrs: {
                              height: "auto",
                              width: "auto",
                              continuous: false,
                              "hide-delimiters": "",
                            },
                          },
                          _vm._l(_vm.renewalStatus, function (item, index) {
                            return _c("v-carousel-item", { key: index }, [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", {}, [_vm._v(" Report by : ")]),
                                  _c("p", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.fname) +
                                        " " +
                                        _vm._s(item.lname) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", {}, [_vm._v(" Report date : ")]),
                                  _c("p", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.convertTimeZone(item.created_at)
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", {}, [_vm._v(" Renewal status : ")]),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                item.renewal_status == "Pending"
                                                  ? _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "white--text mt-n1",
                                                            staticStyle: {
                                                              "border-radius":
                                                                "13px",
                                                            },
                                                            attrs: {
                                                              color: "orange",
                                                            },
                                                          },
                                                          "v-chip",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.renewal_status
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.renewal_status == "Confirm"
                                                  ? _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "white--text mt-n1",
                                                            staticStyle: {
                                                              "border-radius":
                                                                "13px",
                                                            },
                                                            attrs: {
                                                              color:
                                                                "green accent-3",
                                                            },
                                                          },
                                                          "v-chip",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.renewal_status
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.renewal_status ==
                                                "Confirm - Pending HRDC"
                                                  ? _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "white--text mt-n1",
                                                            staticStyle: {
                                                              "border-radius":
                                                                "13px",
                                                            },
                                                            attrs: {
                                                              color:
                                                                "green accent-3",
                                                            },
                                                          },
                                                          "v-chip",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.renewal_status
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.renewal_status ==
                                                "Unreachable"
                                                  ? _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "white--text mt-n1",
                                                            staticStyle: {
                                                              "border-radius":
                                                                "13px",
                                                            },
                                                            attrs: {
                                                              color: "grey",
                                                            },
                                                          },
                                                          "v-chip",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.renewal_status
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.renewal_status ==
                                                "Rejected"
                                                  ? _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "white--text mt-n1",
                                                            staticStyle: {
                                                              "border-radius":
                                                                "13px",
                                                            },
                                                            attrs: {
                                                              color: "red",
                                                            },
                                                          },
                                                          "v-chip",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.renewal_status
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(item.status) + " "),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              item.confirm_at != null
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", {}, [
                                        _vm._v(" Client payment date : "),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.convertTimeZone(
                                                item.confirm_at
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              item.renewal_status == "Pending" &&
                              item.next_followup_at != null
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", {}, [
                                        _vm._v(" Next follow up date : "),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.convertTimeZone(
                                                item.next_followup_at
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              item.renewal_status == "Rejected" &&
                              item.refund != null
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", {}, [_vm._v(" Refund : ")]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.refund == "1" ? "Yes" : "No"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {},
                                [
                                  _c("p", {}, [_vm._v(" Remark : ")]),
                                  _c("mavon-editor", {
                                    staticStyle: {
                                      "min-height": "100px",
                                      width: "100%",
                                      "max-height": "100px",
                                      "overflow-y": "auto",
                                      "border-radius": "0.5rem",
                                    },
                                    attrs: {
                                      previewBackground: "#ECEFF1",
                                      boxShadow: false,
                                      width: "auto",
                                      defaultOpen: "preview",
                                      toolbarsFlag: false,
                                      subfield: false,
                                      language: "en",
                                      editable: false,
                                    },
                                    model: {
                                      value: item.remark,
                                      callback: function ($$v) {
                                        _vm.$set(item, "remark", $$v)
                                      },
                                      expression: "item.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between my-3",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-bold mt-2" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(index + 1) +
                                          " / " +
                                          _vm._s(_vm.totalRenewalStatus) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "custom-button text-capitalize",
                                      attrs: { outlined: "" },
                                      on: { click: _vm.readAllRenewalUpdate },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v(" Show All ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", [
                      _c("span", [_vm._v("No renewal status update")]),
                    ]),
              ]),
            ],
            1
          ),
      _c("hr", { staticStyle: { border: "2px solid #F2F3F7" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }