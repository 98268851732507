var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
                  _vm._v(" Refund attachment "),
                ]),
                Object.keys(_vm.attachment).length != 0
                  ? _c(
                      "div",
                      [
                        _vm._l(this.attachment, function (item, index) {
                          return _c("div", { key: index }, [
                            index <= 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "pa-3 mb-2",
                                        attrs: {
                                          flat: "",
                                          color: "blue-grey lighten-5",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s("#" + (index + 1)) +
                                              " " +
                                              _vm._s(item.attachment_type) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "v-row",
                                          { staticClass: "mt-3" },
                                          [
                                            _c("v-col", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "text-decoration-none",
                                                  attrs: {
                                                    href: item.attachment_url,
                                                    target: "_blank",
                                                    download: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.attachment_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("v-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-end",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.convertTimeZone(
                                                              item.created_at
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-start",
                                                  },
                                                  [_vm._v(" Remark : ")]
                                                ),
                                                _c("mavon-editor", {
                                                  staticStyle: {
                                                    "min-height": "100px",
                                                    width: "100%",
                                                    "max-height": "100px",
                                                    "overflow-y": "auto",
                                                    "border-radius": "0.5rem",
                                                  },
                                                  attrs: {
                                                    previewBackground:
                                                      "#ECEFF1",
                                                    boxShadow: true,
                                                    width: "auto",
                                                    defaultOpen: "preview",
                                                    toolbarsFlag: false,
                                                    subfield: false,
                                                    language: "en",
                                                    editable: false,
                                                  },
                                                  model: {
                                                    value: item.remark,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "remark",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.remark",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        }),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end mt-3 mb-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "custom-button text-capitalize",
                                attrs: {
                                  outlined: "",
                                  to: {
                                    name: "PageAttachmentFormCompany",
                                    params: {
                                      id: this.$router.history.current.params
                                        .id,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(" Show All ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                Object.keys(_vm.attachment).length == 0 &&
                _vm.api.isLoading == false
                  ? _c("div", [
                      _c(
                        "span",
                        { staticClass: "d-flex justify-start text-h7" },
                        [_vm._v(" No Refund attachment recorded ")]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("hr", { staticStyle: { border: "2px solid #F2F3F7" } }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }