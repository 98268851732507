var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingPayment
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      !this.isLoadingPayment
        ? _c(
            "v-tabs",
            {
              staticClass: "custom-tabs",
              attrs: {
                "active-class": "custom-active-tab",
                "slider-color": "transparent",
                "fixed-tabs": "",
              },
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "text-capitalize rounded-l black--text",
                  staticStyle: { "border-width": "2px 2px 2px 1px" },
                },
                [_vm._v(" latest payment "), _c("br"), _vm._v(" status ")]
              ),
              _c(
                "v-tab",
                {
                  staticClass: "text-capitalize  black--text",
                  staticStyle: { "border-width": "2px 2px 2px 1px" },
                },
                [_vm._v(" Payment method ")]
              ),
              _c(
                "v-tab",
                {
                  staticClass: "text-capitalize rounded-r black--text",
                  staticStyle: { "border-width": "2px 2px 2px 1px" },
                },
                [_vm._v(" Refund status ")]
              ),
              _c("v-tab-item", [
                _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
                  _vm._v(" Latest Payment Status "),
                ]),
                _vm.latestPaymentForm != null
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("p", {}, [_vm._v(" Report by : ")]),
                          _c("p", {}, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.latestPaymentForm.fname) +
                                " " +
                                _vm._s(_vm.latestPaymentForm.lname) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("p", {}, [_vm._v(" Report date : ")]),
                          _c("p", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertTimeZone(
                                    _vm.latestPaymentForm.created_at
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _vm.latestPaymentForm.total_amount != null
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c("p", {}, [_vm._v(" Payment date : ")]),
                              _c("p", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.convertTimeZone(
                                        _vm.latestPaymentForm.payment_at
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.latestPaymentForm.total_amount != null
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c("p", {}, [_vm._v(" Amount : ")]),
                              _c("p", {}, [
                                _vm._v(
                                  " RM " +
                                    _vm._s(_vm.latestPaymentForm.total_amount) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.latestPaymentForm.payment_method != null
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c("p", {}, [_vm._v(" Payment method : ")]),
                              _c("p", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.latestPaymentForm.payment_method
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("p", {}, [_vm._v(" Status : ")]),
                          _c(
                            "div",
                            { staticClass: "mt-n1" },
                            [
                              _vm.latestPaymentForm.status == "Pending"
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "white--text",
                                      staticStyle: { "border-radius": "13px" },
                                      attrs: { color: "orange" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.latestPaymentForm.status) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.latestPaymentForm.status == "Success"
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "white--text",
                                      staticStyle: { "border-radius": "13px" },
                                      attrs: { color: "green accent-3" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.latestPaymentForm.status) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.latestPaymentForm.status == "Fail"
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "white--text",
                                      staticStyle: { "border-radius": "13px" },
                                      attrs: { color: "red" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.latestPaymentForm.status) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {},
                        [
                          _c("p", {}, [_vm._v(" Remark : ")]),
                          _c("v-textarea", {
                            staticClass: "rounded-lg",
                            attrs: {
                              flat: "",
                              solo: "",
                              "no-resize": "",
                              "background-color": "blue-grey lighten-5",
                              readonly: "",
                            },
                            model: {
                              value: _vm.latestPaymentForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.latestPaymentForm, "remark", $$v)
                              },
                              expression: "latestPaymentForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end mt-n4 mb-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "custom-button text-capitalize",
                              attrs: { outlined: "" },
                              on: { click: _vm.readAllPaymentUpdate },
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(" Show All "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c("div", [
                      _c("span", [_vm._v("No payment status update")]),
                    ]),
              ]),
              _c(
                "v-tab-item",
                [_c("ComponentPrototypeCompanyPaymentMethod")],
                1
              ),
              _c(
                "v-tab-item",
                [_c("ComponentPrototypeCompanyRefundStatus")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("hr", { staticStyle: { border: "2px solid #F2F3F7" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }