var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingPayment
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      !this.isLoadingPayment
        ? _c("div", [
            _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
              _vm._v(" Payment Method "),
            ]),
            _c("div", { staticClass: "d-flex justify-space-between" }, [
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(" Credit Card "),
              ]),
            ]),
            _c("div", { staticClass: "d-flex justify-space-between" }, [
              _c("p", {}, [_vm._v(" Brand : ")]),
              _c("p", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.company.card_brand ? _vm.company.card_brand : "-"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "d-flex justify-space-between" }, [
              _c("p", {}, [_vm._v(" Last 4 digits : ")]),
              _c("p", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.company.card_last_four
                        ? _vm.company.card_last_four
                        : "-"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "d-flex justify-space-between" }, [
              _c("p", {}, [_vm._v(" Expired at : ")]),
              _c("p", {}, [
                _vm._v(
                  " " +
                    _vm._s(_vm.company.expired ? _vm.company.expired : "-") +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }