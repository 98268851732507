var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingVerification },
        on: {
          confirm: _vm.updateCompanyVerification,
          cancel: _vm.cancelUpdateCompanyVerification,
        },
      }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function () {
            _vm.api.isSuccesful = false
          },
        },
      }),
      _c("AError", { attrs: { api: this.api } }),
      this.isLoadingCompany
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      !this.isLoadingCompany
        ? _c(
            "div",
            [
              _c("div", { staticClass: "text-h6 my-4 font-weight-bold" }, [
                _vm._v(" Company Details "),
              ]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c("p", { staticClass: "text-no-wrap" }, [_vm._v(" Name : ")]),
                _c("p", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.company.name) + " "),
                ]),
              ]),
              this.allowedRoleUpdateStatus.some(function (role) {
                return this$1.$store.getters.getRole.includes(role)
              })
                ? _c("div", { staticClass: "d-flex justify-space-between" }, [
                    _c("p", { staticClass: "text-no-wrap" }, [
                      _vm._v(" Stripe : "),
                    ]),
                    _c("p", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.company.company_stripe_id != null
                              ? _vm.company.company_stripe_id
                              : null
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c("p", { staticClass: "text-no-wrap" }, [_vm._v(" SSM : ")]),
                _c("p", { staticClass: "text-right" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.company.ssm_no ? _vm.company.ssm_no : "-") +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c("p", { staticClass: "text-no-wrap" }, [
                  _vm._v(" Address : "),
                ]),
                _c("p", { staticClass: "text-right" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.company.address ? _vm.company.address : "-") +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c("p", { staticClass: "text-no-wrap" }, [
                  _vm._v(" Industry : "),
                ]),
                _c("p", { staticClass: "text-right" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.company.industry ? _vm.company.industry : "-"
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c("p", { staticClass: "text-no-wrap" }, [_vm._v(" AAE : ")]),
                _c("p", { staticClass: "text-right" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.company.minutes != null
                          ? _vm.company.minutes + " Minutes"
                          : "-"
                      ) +
                      " "
                  ),
                ]),
              ]),
              this.allowedRoleUpdateStatus.some(function (role) {
                return this$1.$store.getters.getRole.includes(role)
              })
                ? _c("div", { staticClass: "d-flex justify-space-between" }, [
                    _c("p", { staticClass: "text-no-wrap" }, [
                      _vm._v(" Status : "),
                    ]),
                  ])
                : _vm._e(),
              _vm.isLoadingUpdateVerification
                ? _c("v-progress-circular", {
                    attrs: { size: 50, color: "primary", indeterminate: "" },
                  })
                : _c("v-select", {
                    attrs: {
                      dense: "",
                      outlined: "",
                      items: _vm.companyVerificationOption,
                    },
                    on: { change: _vm.validateInputChangeVerification },
                    model: {
                      value: _vm.company.verification,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "verification", $$v)
                      },
                      expression: "company.verification",
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _c("hr", { staticStyle: { border: "2px solid #F2F3F7" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }